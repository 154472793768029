*{
    box-sizing: border-box;
}

html, body{
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: 97%;
    font-family: "monotype-grotesque", sans-serif;
    font-weight: 400;
    font-style: normal;
}

body{
    // background: #E9E9E9;
    background: #000000;
    color: #ccc;
}

header{
    z-index: 98;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem;
    cursor: pointer;
}

h1{
    font-weight: 400;
    margin: 0;
    font-size: 1rem;
    word-spacing: -.25ch;
    letter-spacing: .015ch;
}

p{
    margin: 0;
    font-size: 1rem;
    word-spacing: -.1ch;
    letter-spacing: .005ch;
}

body.loaded{
    section#about{
        transition: transform .5s;
    }
}


section#about{
    z-index: 100;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 1rem;
    //background-color: white;
    background: #000000;
    color: #ccc;
    transform: translateY(-100%);
    p{
        max-width: 500px;
    }
    .links{
        list-style-type: none;
        margin: 1rem 0 0;
        padding: 0;
        display: flex;
        gap: 10px;
        a{
            color: inherit;
            text-decoration: none;
        }
    }
    .lang-switch{
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 1rem;
        color: inherit;
        text-decoration: none;
    }
}

#overlay{
    z-index: 99;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity .5s;
    pointer-events: none;
}

body.active{
    section#about{
        transform: translateY(0);
    }
    #overlay{
        opacity: .5;
        pointer-events: all;
    }
}



section#content{
    width: 100%;
    height: 100%;
}

.swiper{
    position: relative;
    width: 100%;
    height: 100%;
    //background-color: #eaeaea;
    background: #000000;
    color: #E9E9E9;
    pointer-events: none;
}

.swiper .overlay{
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 98;
    pointer-events: all;
    &.left{
        left: 0;
        cursor:w-resize
    }
    &.right{
        right: 0;
        cursor:e-resize
    }
}

.swiper-slide{
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.swiper-slide figure{
    width: 100%;
    height: 100%;
    position: relative;
    padding: .25rem .25rem 1.25rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide figure picture{
    display: block;
    margin: 0 auto;
    width: 100vh;
    max-width: calc(100% - .5rem);
    max-height: 100vw;
    height: calc(100% - 1.5rem);
}

.swiper-slide figure img{
    object-fit: contain;
    object-position: 50% 47% !important;
    height: 100%;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .swiper-slide figure{ 
        padding: 2rem 2rem 2.5rem;
    }
    .swiper-slide figure picture{
        max-width: calc(100% - 4rem);
        max-height: auto;
        height: calc(100% - 4.5rem);
    }
}



.swiper-slide figcaption{
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1rem;
    //color: #222;
    color: #bbbbbb;
}

@media screen and (orientation: landscape) {
    .swiper-slide figure.dark{
        position: relative;
        figcaption{
            //color: #fff;
            z-index: 1;
        }
    }
}

.swiper-slide img,
.swiper-slide picture{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


