* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: monotype-grotesque, sans-serif;
  font-size: 97%;
  font-style: normal;
  font-weight: 400;
}

body {
  color: #ccc;
  background: #000;
}

header {
  z-index: 98;
  cursor: pointer;
  padding: 1rem;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

h1 {
  word-spacing: -.25ch;
  letter-spacing: .015ch;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

p {
  word-spacing: -.1ch;
  letter-spacing: .005ch;
  margin: 0;
  font-size: 1rem;
}

body.loaded section#about {
  transition: transform .5s;
}

section#about {
  z-index: 100;
  color: #ccc;
  background: #000;
  width: 100%;
  height: auto;
  padding: 1rem;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

section#about p {
  max-width: 500px;
}

section#about .links {
  gap: 10px;
  margin: 1rem 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

section#about .links a {
  color: inherit;
  text-decoration: none;
}

section#about .lang-switch {
  color: inherit;
  padding: 1rem;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

#overlay {
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  background-color: #000;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

body.active section#about {
  transform: translateY(0);
}

body.active #overlay {
  opacity: .5;
  pointer-events: all;
}

section#content {
  width: 100%;
  height: 100%;
}

.swiper {
  color: #e9e9e9;
  pointer-events: none;
  background: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper .overlay {
  z-index: 98;
  pointer-events: all;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
}

.swiper .overlay.left {
  cursor: w-resize;
  left: 0;
}

.swiper .overlay.right {
  cursor: e-resize;
  right: 0;
}

.swiper-slide {
  -webkit-backface-visibility: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: translateZ(0);
}

.swiper-slide figure {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: .25rem .25rem 1.25rem;
  display: flex;
  position: relative;
}

.swiper-slide figure picture {
  width: 100vh;
  max-width: calc(100% - .5rem);
  height: calc(100% - 1.5rem);
  max-height: 100vw;
  margin: 0 auto;
  display: block;
}

.swiper-slide figure img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: 50% 47% !important;
}

@media screen and (width >= 768px) {
  .swiper-slide figure {
    padding: 2rem 2rem 2.5rem;
  }

  .swiper-slide figure picture {
    max-width: calc(100% - 4rem);
    max-height: auto;
    height: calc(100% - 4.5rem);
  }
}

.swiper-slide figcaption {
  color: #bbb;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (orientation: landscape) {
  .swiper-slide figure.dark {
    position: relative;
  }

  .swiper-slide figure.dark figcaption {
    z-index: 1;
  }
}

.swiper-slide img, .swiper-slide picture {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=index.6278be49.css.map */
